function HeaderFilter(props) {
  const [toggleFilter, setToggleFilter] = React.useState(false);
  const [dateTime, setDateTime] = React.useState({
    start_date: moment().subtract(6, "days"),
    end_date: moment(),
  });
  const [toggleLabel, setToggleLabel] = React.useState(false);
  const [toggleContact, setToggleContact] = React.useState(false);
  const [labelList, setlabelList] = React.useState([]);
  const [contactList, setContactList] = React.useState([]);
  const [contactSearch, setContactSearch] = React.useState([]);
  const [searchTextLabel, setSearchTextLabel] = useState("");

  let isMobile = window.innerWidth < 768;

  React.useEffect(() => {
    clearFilter();
  }, []);

  function checkLabel(label_val) {
    let checkLabel = [...labelList];
    if (!(labelList.indexOf(label_val) > -1) && checkLabel.length < 5) {
      checkLabel.push(label_val);
    } else {
      checkLabel = checkLabel.filter((val) => val !== label_val);
    }
    setlabelList(checkLabel);
  }

  function checkContact(contact_val) {
    let checkContact = [...contactList];

    if (
      _.find(checkContact, { name: contact_val.name }) &&
      checkContact.length > 1
    ) {
      checkContact = checkContact.filter(
        (val) => val.name !== contact_val.name
      );
    } else if (!_.find(checkContact, { name: contact_val.name })) {
      checkContact.push(contact_val);
    }
    setContactList(checkContact);
  }

  function isSelected(check, type) {
    if (type === "contact") {
      return _.findIndex(contactList, { name: check }) > -1;
    } else {
      return _.findIndex(labelList, { label_id: check }) > -1;
    }
  }

  function clearFilter() {
    let contact = [{ name: "Widget", color: "#6DA7FD" }];
    props.r_chat_link &&
      contact.push({ name: "Chatday_Link", color: "#EDB435" });
    _.find(props.chatCenter, { type: "line" }) &&
      contact.push({ name: "LINE", color: "#00B900" });
    _.find(props.chatCenter, { type: "facebook" }) &&
      contact.push({ name: "Facebook", color: "#1778F2" });
    _.find(props.chatCenter, { type: "instagram" }) &&
      contact.push({ name: "Instagram", color: "#E4405F" });

    setlabelList([]);
    setContactSearch(contact);
    setContactList(contact);
    setSearchTextLabel("");
    setToggleLabel(false);
    setToggleContact(false);
    let defaultStartDate = moment().subtract(6, "days");
    let defaultEndDate = moment();
    let defaultDate = {
      start_date: defaultStartDate,
      end_date: defaultEndDate,
    };
    setDateTime(defaultDate);
    props.getReport(defaultDate, contact, [], 20);
  }

  const toggleFilterButton = (toggle) => {
    setToggleFilter(toggle);
  }

  const filteredLabels =
    props.label &&
    Object.entries(props.label)
      .map((val) => {
        return val[1];
      })
      .filter((label) =>
        label.name.toLowerCase().includes(searchTextLabel.toLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div style={{ borderBottom: "1px solid #E1E7F2" }}>
      <CardHeaderFilter>
        <div>
          <div>
            <span class="sp-label">{props.lang.__insight_of_chat}</span>
          <ReactTooltip text={props.lang.__manual_insight_of_chat} tooltipWidth={160} position={"bottom"}>
                <a
                href="https://help.readyplanet.com/rchat/insight-of-chat"
                target="_blank"
              >
                <i
                  class="icon-help-circled sp-link"
                  tooltip-placement="right"
                ></i>
              </a>
              </ReactTooltip>
          </div>
          
          <div
            class="sp-text-help"
            style={{ fontSize: "12px", display: isMobile ? "block" : "inline" }}
          >
            {props.lang.__report_date_range}{" "}
            {props.formatDateCustom(dateTime.start_date, "DD MMM YY")} -{" "}
            {props.formatDateCustom(dateTime.end_date, "DD MMM YY")}
          </div>
          <div
            class="sp-text-help"
            style={{ fontSize: "12px", display: isMobile ? "block" : "inline" }}
          >
            &nbsp;{contactList.length} {props.lang.__contact}
            {labelList.length > 0
              ? ` ${labelList.length} ${props.lang.__label}`
              : ""}
          </div>
        </div>
        <FilterButton
        onClick={() => toggleFilterButton(!toggleFilter)}
        data-toggle="collapse"
        data-target="#collapseExample"
        aria-expanded="false"
        aria-controls="collapseExample">
          <div class="sp-label" style={{ marginRight: "10px" }}>
            {props.lang.__report_filter}
          </div>
          <IconChevron toggle={toggleFilter}>
            <i class="fa fa-chevron-down"></i>
          </IconChevron>
        </FilterButton>
      </CardHeaderFilter>
      <div class="collapse" id="collapseExample" style={{ padding: "0 20px" }}>
        <DatePicker
          lang={props && props.lang}
          time={dateTime}
          callbackData={(data) => {
            setDateTime(data);
          }}
        />
        <LabelAndContactButton isMobile={isMobile}>
          <Button
            onClick={() => {
              setToggleContact(!toggleContact);
              setToggleLabel(false);
            }}
          >
            {props.lang.__report_select_contact}
          </Button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: isMobile ? 0 : 10,
              flexWrap: "wrap",
            }}
          >
            {contactList &&
              contactList.map((val) => {
                return (
                  <TagsLabel
                    color={"#6da7fd"}
                    style={{ marginTop: isMobile ? 10 : 4, marginRight: 10 }}
                  >
                    <div style={{ marginRight: 10 }}>
                      {val.name === "Chatday_Link" ? "R-Chat Link" : val.name}
                    </div>
                    <div
                      onClick={() => checkContact(val)}
                      style={{ cursor: "pointer" }}
                    >
                      X
                    </div>
                  </TagsLabel>
                );
              })}
          </div>
        </LabelAndContactButton>
        {toggleContact && (
          <div
            class="accordion-body"
            style={{
              zIndex: 11,
              position: "sticky",
              marginTop: "6px",
              minWidth: "240px",
              width: "20%",
            }}
          >
            <div
              class="sp-label"
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: 10,
                border: "1px solid #E1E7F2",
              }}
            >
              <div>
                <div>{props.lang.__contact}</div>
                <div
                  class="sp-text-help"
                  style={{ fontSize: 12, fontWeight: 400 }}
                >
                  {props.lang.__report_limit_chanel}
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setToggleContact(!toggleContact);
                  setToggleLabel(false);
                }}
              >
                X
              </div>
            </div>
            <div class="sp-row">
              <div class="tag-label-selector">
                <div class="body">
                  {contactSearch &&
                    contactSearch.map((val) => {
                      return (
                        <div
                          class="sp-row choice"
                          onClick={() => checkContact(val)}
                        >
                          <div class="sp-col">
                            <div class="sp-checkbox sp-row">
                              <input
                                type="checkbox"
                                class="sp-input sp-col -wrap"
                                checked={isSelected(val.name, "contact")}
                              />
                              <span class="sp-text sp-col -ellipsis">
                                {val.name === "Chatday_Link"
                                  ? "R-Chat Link"
                                  : val.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        )}
        {!_.isEmpty(props.label) ? (
          <div>
            <LabelAndContactButton isMobile={isMobile}>
              <Button
                onClick={() => {
                  setToggleContact(false);
                  setToggleLabel(!toggleLabel);
                }}
              >
                {props.lang.__report_select_label}
              </Button>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: isMobile ? 0 : 10,
                  flexWrap: "wrap",
                }}
              >
                {labelList &&
                  labelList.map((val) => {
                    return (
                      <TagsLabel
                        color={val.color}
                        style={{
                          marginTop: isMobile ? 10 : 0,
                          marginRight: 10,
                        }}
                      >
                        <div
                          style={{
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            marginRight: 10,
                            textOverflow: "ellipsis",
                          }}
                        >
                          {val.name}
                        </div>
                        <div
                          onClick={() => checkLabel(val)}
                          style={{ cursor: "pointer" }}
                        >
                          X
                        </div>
                      </TagsLabel>
                    );
                  })}
              </div>
            </LabelAndContactButton>
            {toggleLabel && (
              <div
                class="accordion-body"
                style={{
                  zIndex: 11,
                  position: "sticky",
                  marginTop: "6px",
                  minWidth: "240px",
                  width: "20%",
                }}
              >
                <div
                  class="sp-label"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: 10,
                    border: "1px solid #E1E7F2",
                  }}
                >
                  <div>
                    {props.lang.__label} ({labelList.length}/5)
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setToggleLabel(!toggleLabel);
                      setToggleContact(false);
                    }}
                  >
                    X
                  </div>
                </div>
                <div class="sp-row">
                  <input
                    type="text"
                    class="sp-input"
                    placeholder={props.lang.__chat_add_label}
                    value={searchTextLabel}
                    onChange={(e) => setSearchTextLabel(e.target.value)}
                  />
                </div>
                <div class="sp-row">
                  <div class="tag-label-selector">
                    <div class="body">
                      {props.label &&
                        filteredLabels.map((val) => {
                          return (
                            <div
                              class="sp-row choice"
                              onClick={() => checkLabel(val)}
                            >
                              <div class="sp-col">
                                <div class="sp-checkbox sp-row">
                                  <input
                                    type="checkbox"
                                    class="sp-input sp-col -wrap"
                                    checked={isSelected(val.label_id, "label")}
                                  />
                                  <span class="sp-text sp-col -ellipsis">
                                    {val.name}
                                  </span>
                                </div>
                              </div>
                              <div class="sp-col -wrap -align-middle">
                                <i
                                  class="icon icon-label"
                                  style={{ color: `${val.color}` }}
                                ></i>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <ButtonCancel
            style={{ color: "#6DA7FD" }}
            onClick={() => {
            clearFilter();
            setToggleFilter(!toggleFilter);
            }}
            data-toggle="collapse"
            data-target="#collapseExample"
          >
            {props.lang.__report_clear}
          </ButtonCancel>
          <Button
            color="primary"
            onClick={() => {
              props.getReport(dateTime, contactList, labelList, 20);
              setToggleFilter(!toggleFilter);
              setToggleContact(false);
              setToggleLabel(false);
            }}
            class="success"
            data-toggle="collapse"
            data-target="#collapseExample"
          >
            {props.lang.__analytic_apply}
          </Button>
        </div>
      </div>
    </div>
  );
}

const rotateDown = window.styled.keyframes`
from { transform:  rotate(0deg); }
  to { transform:  rotate(180deg); }
`;

const rotateUp = window.styled.keyframes`
from { transform:  rotate(180deg); }
  to { transform:  rotate(0deg); }
`;

const CardHeaderFilter = window.styled.div`
${(props) =>
  props.onClick
    ? `
        cursor: pointer;
      `
    : ``}

display: flex;
align-items: center;
justify-content: space-between;
height: 70px;
padding: 0 20px;
border-bottom: 1px solid #E1E7F2;
`;

const IconChevron = window.styled.div`
animation: ${(props) => (props.toggle ? rotateDown : rotateUp)} 0.2s linear;
animation-fill-mode: forwards;
`;

const Button = window.styled.button`
border: 1px solid transparent;
border-radius: 4px;
height: 40px;
max-width: 146px;
min-width: 146px;
min-height: 40px;
background-color: ${(props) => (props.color === 'primary' ? "#6DA7FD" : "#EFF5FF")};
color: ${(props) => (props.color === 'primary' ? "white" : "#65A6FF")};
 &:hover {
    background-color: ${(props) => (props.color === 'primary' ? "#4791FF" : "#E4EEFF")};
    color: ${(props) => (props.color === 'primary' ? "white" : "#65A6FF")};
 }
`;

const ButtonCancel = window.styled.span`
border: 1px solid transparent;
border-radius: 4px;
color: #AFBACF;
cursor: pointer;
padding: 5px 10px;
margin-right: 20px;
 &:hover {
    background-color: #EFF5FF;
 }
`;

const TagsLabel = window.styled.div`
display: flex;
padding: 0 10px;
margin-left: 10px;
justify-content: space-between;
align-items: center;
border: 1px solid ${(props) => (props.color ? props.color : "black")};
width: 114px;
height: 28px;
border-radius: 20px;
font-size: 12px;
color: ${(props) => (props.color ? props.color : "black")};
`;

const LabelAndContactButton = window.styled.div`
display: flex;
flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
margin-top: 10px;
`;

const FilterButton = window.styled.div`
display: flex;
min-width: 80px;
cursor: pointer;
padding: 4px 12px;
&:hover {
  background-color: #F1F6FF;
}
`;
